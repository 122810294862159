import dayjs from 'dayjs'

export default function (blob,name='') {
  if(!name){
    name=dayjs().format('YYYYMMDDhhmmssSSS') + '.xlsx'
  }
  const url = window.URL.createObjectURL(new Blob([blob], {type: 'application/vnd.ms-excel'}))
  const link = document.createElement('a')

  link.href = url
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
